import React, { useEffect, useState } from 'react';
import HeaderNav from "../components/HeaderNav";
import Container from 'react-bootstrap/Container';
import Footer from "../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetMeQuery, useGetOrdersListQuery, useLazyGetOrdersListQuery } from "../slices";
import { Spinner } from "react-bootstrap";

export default function Invoice() {
    const
        statuses: any = {
            created: "Создан",
            paid: "Оплачен",
            cancelled: "Отменен"
        }
    const { data: meData, isLoading: meIsLoading, error, } = useGetMeQuery("");
    const { id: paramsId }: any = useParams()
    const [invoiceId, setInvoiceId] = useState(paramsId);
    let { data: ordersList, isLoading: orderListIsLoading } = useGetOrdersListQuery("/" + (invoiceId), { skip: meIsLoading, pollingInterval: 5000 });
    const navigate = useNavigate();

    if (meIsLoading || orderListIsLoading || !ordersList) {
        return (
            <>  <HeaderNav />
                <Container >
                    <div className='m-5 border shadow'>
                        <Spinner animation="border" />
                    </div>
                </Container>
                <Footer />
            </>
        )
    }
    if (error) {
        navigate('/signin');
    } else {
        if (!(meData?.isKYCPassed)) {
            navigate('/kyc');
        }
    }
    //console.log(ordersList);
    return (
        <>
            <HeaderNav></HeaderNav>

            <div className="container-fluid mb-5">
                <div className="row overflow-hidden">

                    <div className="col card border shadow m-lg-5 p-2 p-lg-5">
                        <div className='mb-1'><Link className="btn btn-outline-primary" to={{ pathname: "/dashboard" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                            </svg>
                            Назад
                        </Link></div>
                        <h5 className="card-header">Запрос на покупку от {(new Date(ordersList?.createdAt)).toLocaleString()}</h5>
                        <div className="card-body overflow-auto">

                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Сумма к покупке (USDT)</th>
                                        <th scope="col">Сумма к оплате (фиат)</th>
                                        <th scope="col">Валютная пара</th>
                                        <th scope="col">Статус заказа на покупку</th>
                                        <th scope="col">Статус выплаты по заказу</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Link to={`/invoice/${ordersList.id}`}> </Link></td>
                                        <td>{Number(ordersList.cryptoAmount).toFixed(2)}</td>
                                        <td>{ordersList.fiatAmount}</td>
                                        <td>{ordersList.currencyPair}</td>
                                        <td>{statuses[ordersList.invoiceStatus]}</td>
                                        <td>{statuses[ordersList.payoutStatus]}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <ul className="list-group ">
                            <li className="list-group-item">Дополнительная информация об операции на <strong>tronscan.org:</strong> <a target="_blank" className="alert-link" href={`${ordersList?.payoutExplorerLink}`}>{`${ordersList?.payoutID}`}</a></li>
                        </ul>
                        {(ordersList.invoiceQR ? <> <h3>Заказ не оплачен.</h3> <p>Отсканируйте/нажмите на QR-код и следуйте инструкциям в приложении</p> <div className="my-3 text-center"><a href={ordersList.invoiceLinkApp} className='pulse'><img src={ordersList.invoiceQR} className="rounded img-thumbnail" alt="..." /></a></div><div className='text-center'>Ожидание оплаты <br /><Spinner></Spinner></div> </> : "")}


                    </div>

                </div>
            </div >
            <Footer></Footer>
        </>
    )



};
