import { useLocation, Navigate } from "react-router-dom";
import { useGetMeQuery } from "../slices";
import { Spinner } from "react-bootstrap";

const RequireAuth = ({ children }: any) => {
    const { data: meData, isLoading: meIsLoading, error, } = useGetMeQuery("");

    const location = useLocation();

    if (meIsLoading) {
        return (
            <div className=' '>
                <Spinner animation="border" />
            </div>
        )
    }
    if (error) {
        return <Navigate to='/signin' state={{ from: location }}></Navigate>
    }
    else {
        if ((!(meData?.isKYCPassed)) && (location.pathname != "/kyc")) {
            return <Navigate to='/kyc' state={{ from: location }}></Navigate>
        }
    }
    return children
}

export { RequireAuth }