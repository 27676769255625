import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import HeaderNav from '../components/HeaderNav';
import { Row, Spinner } from 'react-bootstrap';
import Footer from '../components/Footer';
// @ts-ignore 
import SumsubWebSdk from '@sumsub/websdk-react';
import { useGetSumSubTokenQuery } from '../slices';
import BackButton from '../components/BackButton';
import { Link } from 'react-router-dom';

function KnowYourCustomer() {
    const { data: sumsub, isLoading: sumsubIsLoading } = useGetSumSubTokenQuery("");
    const [continueButtonVisibility, setContinueButtonVisibility] = useState(false);

    const accessTokenExpirationHandler = async () => {
        const response = await fetch((process.env.REACT_APP_BASE_URL) + 'sumsub/access-token', { method: 'post', credentials: 'include' });
        const data = await response.json();
        return data.accessToken
    }
    const onMessageHandler = (type: any, message: any) => {

        if (type === "idCheck.moduleResultPresented" && message?.answer === "GREEN") {
            setContinueButtonVisibility(true)
        }
        if (type === "idCheck.applicantStatus") {
            if (message?.reviewResult?.reviewAnswer === "GREEN") {
                setContinueButtonVisibility(true)
            }
        }
    }
    if ((sumsubIsLoading)) return (
        <>  <HeaderNav />
            <Container >
                <div className='m-5 border shadow'>
                    <BackButton></BackButton>
                    <Spinner animation="border" />;
                    <p>Для продолжения требуется пройти подтверждение личности. Это не займет много времени.</p>
                </div>
            </Container>
            <Footer />
        </>
    )

    return (<>  <HeaderNav />
        <Container >

            <div className='m-2 m-l-5 border shadow justify-content-center'>
                <SumsubWebSdk
                    accessToken={sumsub?.accessToken}
                    expirationHandler={accessTokenExpirationHandler}
                    // config={config}
                    // options={options}
                    onMessage={onMessageHandler}

                    // onError={errorHandler}
                    className=""
                />
                {continueButtonVisibility ? <div className='d-block m-5 mx-auto w-25'><Link className='btn btn-success btn-lg' to={'/payment'}>Продолжить покупку валюты</Link></div> : ""}
            </div>
        </Container>
        <Footer />
    </>
    );

}
export default KnowYourCustomer;
