import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useGetMeQuery, useLogOutMutation } from '../slices';

export default function HeaderNav() {
    const { data, isLoading, refetch } = useGetMeQuery("");
    useEffect(() => {
        refetch()
    }, []);
    const [logOut, { isLoading: isLoadinLogOut }] = useLogOutMutation();

    const navigate = useNavigate();
    const handleLogIn = () => {
        navigate('/signin');
    }
    const handleLogOut = async (e: any) => {
        e.preventDefault();
        await logOut("")
            .unwrap()
            .then(() => {
                window.location.replace("/signin"); navigate('');
            })
            .catch((error) => console.error('rejected', error));
    };

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className='mb-20'>
            <Container className='container-fluid'>
                <Navbar.Brand className='d-none d-sm-block'><Link to="/"><img src={require('../img/logo.png')} alt="" height='80' /></Link></Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
                <Nav className="me-auto d-inline-block">
                    <Link className="nav-link p-2" to={{ pathname: "/" }}>Купить USDT</Link>
                    <Link className="nav-link " to={{ pathname: "/dashboard" }}>Мои сделки</Link>
                    {/* <Link className="nav-link" to={{ pathname: "/kyc" }} >KYC</Link> */}
                </Nav>
                <Nav>
                    <div className="nav-link">

                        <a title={data?.id ? 'Выход' : 'Вход'} onClick={data?.id ? handleLogOut : handleLogIn}>{data?.id ? <i className="bx bx-log-out"></i> : <i className="bx bx-log-in"></i>}{data?.id ? (isLoadinLogOut ? <Spinner></Spinner> : data.phone_number) : 'Вход'}</a>
                    </div>
                </Nav>
                {/* </Navbar.Collapse> */}
            </Container>
        </Navbar>
    )
}