import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import HeaderNav from '../components/HeaderNav';
import { Row } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import OtpInput from '../components/OtpInput';
import FormInput from "../components/FormInput";
import { useGetMeQuery, useLogOutMutation, useSendPingMutation, useSendPongMutation } from '../slices'
import { useDispatch } from 'react-redux';

function SignIn() {

  const { data: meData, isLoading: meIsLoading } = useGetMeQuery("");
  const [sendPing, { isLoading: isLoadingPing }] = useSendPingMutation();
  const [sendPong, { isLoading: isLoadingPong }] = useSendPongMutation();
  const [logOut] = useLogOutMutation();

  let isLoading = isLoadingPing || isLoadingPong || meIsLoading;

  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const [otpIsSent, setOtpIsSent] = useState(false);
  const [otpHasError, setOtpHasError] = useState(false);

  const [values, setValues]: any[] = useState({
    tel: ""
  });

  const inputs = [
    {
      id: 1,
      name: "tel",
      type: "tel",
      placeholder: "996 XXX XXXXXX",
      errorMessage:
        "Номер телефона должен содержать только цифры без знака +",
      label: "Номер телефона",
      pattern: "^[0-9]{10,13}$",
      required: true,
    }
  ];

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!otpIsSent) {
      await sendPing({ "phoneNumber": "+" + e.target[0].value });
      await setOtpIsSent(true);
    } else {
      await sendPong({ "phoneNumber": "+" + e.target[0].value, "smsAuthCode": otp.toString() }).unwrap()
        .then(() => {
          navigate('/payment')
        })
        .catch((error) => {
          console.error('rejected', error);
          if (error.status === 404) { console.log(error.status === 404); window.location.reload(); };
          if (error.status === 403) { setOtpHasError(true) }

        });
    }
  };

  const onChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onChangeOtp = (value: string) => {
    setOtp(value);
    setOtpHasError(false)
  };



  const handleLogOut = async () => {
    await logOut("")
      .unwrap()
      .then(() => {
        window.location.replace("/signin");
        navigate('');
      })
      .catch((error) => console.error('rejected', error));
  };

  if (meData?.id) return (
    <>
      <HeaderNav />
      <Container className='col-lg-7 mx-auto mt-5'>
        <Row className=' '>
          <div className='text-center border p-5 shadow'>
            <h1 className="display-5">Вы входили как {meData.phone_number}</h1>
            <br />
            <span onClick={handleLogOut} className="btn btn-danger"> Войти повторно </span>
          </div>
        </Row>
      </Container>
      <Footer></Footer>
    </>
  )

  return (
    <>
      <HeaderNav />
      <Container className='col-lg-7 mx-auto mt-5'>
        <Row className=' '>
          <form onSubmit={handleSubmit} className="border p-5 shadow">
            <h1 className="display-5">Зарегистрируйтесь</h1>

            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            {otpIsSent ? <OtpInput value={otp} valueLength={4} onChange={onChangeOtp} hasError={otpHasError}></OtpInput> : ''}
            <button type="submit" className="btn btn-primary" value="Продолжить" disabled={isLoading}>Продолжить <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg></button>
          </form>

        </Row>
      </Container>
      <Footer></Footer>
    </>
  )
}

export default SignIn;