import { useState } from "react";
import "./forminput.css";

const FormInput = (props: any) => {
    const [focused, setFocused] = useState(false);
    const { label, errorMessage, onChange, id, ...inputProps } = props;

    const handleFocus = (e: React.ChangeEvent<HTMLElement>) => {
        setFocused(true);
    };

    return (
        <div className="mb-3 formInput">
            <label className="form-label">{label}</label>
            <div className="mb-3">
                <input
                    className="form-control"
                    {...inputProps}
                    onChange={onChange}
                    onBlur={handleFocus}
                    onFocus={() =>
                        inputProps.name === "confirmPassword" && setFocused(true)
                    }
                    focused={focused.toString()}
                />
                <span className="error-message">{errorMessage}</span>
            </div>
        </div>
    );
};

export default FormInput;