import { Link } from "react-router-dom";

import HeaderNav from "../components/HeaderNav";
import Container from 'react-bootstrap/Container';
import Footer from "../components/Footer";
import CalcComponent from "../components/CalcComponent";
import Contacts from "../components/Contacts";


export default function Calc() {
    return <>
        <HeaderNav></HeaderNav>
        {/* <Banner /> */}
        <div className="banner-area">
            <div className="shape1"><img src="/images/shape/shape1.png" alt="image" /></div>
            <div className="shape2"><img src="/images/shape/shape2.png" alt="image" /></div>
            <div className="shape3"><img src="/images/shape/shape3.png" alt="image" /></div>
            <div className="banner-image banner-image-woman d-none d-xl-block"><img src="/images/banner/banner-img3.png" alt="image" /></div>
            <Container className="col-lg-7" >
                <div className="row">
                    <h1 className=" display-2">Обмен криптовалюты в Бишкеке</h1>
                    <p>Если вы искали способ как купить криптовалюту в Бишкеке, то вы попали в нужное место! Благодаря нашему сервису вы сможете очень легко купить или продать криптовалюту по лучшему курсу.</p>
                    <CalcComponent />
                </div>
            </Container>
        </div>
        {/* Как начать торговать */}
        <div className="account-create-process-area bg-image pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>Как начать покупать криптовалюту?</h2>
                    <p>Всего несколько протых шагов отделяют вас от покупки криптовалюты</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-process-item">
                            <div className="icon"><img src="/images/icon/icon1.png" alt="image" /></div>
                            <h3>Зарегистрируйтесь</h3>
                            <p>Проидите быстую регистацию включающую идентификацию личности. Это быстро.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-process-item">
                            <div className="icon"><img src="/images/icon/icon13.png" alt="image" /></div>
                            <h3>Подготовьте кошелек</h3>
                            <p>Для получения криптовалюты вам необходимо быть зарегистрированным в сервисе <a href="https://dengi.kg/"><img src="https://dengi.kg/img/logo.svg" height="30"></img> </a> и иметь криптокошелек для получения купленной криптовалюты</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-process-item">
                            <div className="icon"><img src="/images/icon/icon3.png" alt="image" /></div>
                            <h3>Можно начинать покупки!</h3>
                            <p>Это все, что нужно для того, чтобы начать покупать криптовалюту на нашем сайте.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Best Sellers */}
        <div className="best-seller-area  ">
            <div className="container">
                <div className="row justify-content-center pt-4">
                    <div className="col-xl-3 col-lg-12 col-md-12">
                        <div className="best-seller-image best-seller-image-woman d-none d-xl-block "><img src="/images/women.png" alt="image" className="" /></div>
                    </div>
                    <div className="col-xl-5 col-lg-12 col-md-12">
                        <div className="best-seller-content">
                            <div className="section-title">
                                <h2>Лицензия</h2>
                                <p>Работая с нами вы можете быть уверены в надежности и безопасности. Наша компания обладает государственной лицензией на осуществление деятельности виртуальных активов
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12 col-md-12 best-seller-image">
                        <img src={require('../img/licence.jpg')} alt="image" className="pb-5" />
                    </div>
                </div>
            </div>
            <div className="shape14"><img src="/images/shape/shape14.png" alt="image" className="" /></div>
        </div>
        <Contacts />
        <Footer></Footer>
    </>
};