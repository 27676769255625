import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit'
import axios from 'axios'
const instance = axios.create({
  baseURL: (process.env.REACT_APP_BASE_URL)
});

export const whoAmI: any = createAsyncThunk(
  'auth/whoAmI',
  async function () {
    const response = await fetch((process.env.REACT_APP_BASE_URL) + 'profile/me', { credentials: 'include' });
    const data = await response.json();
    return data
  }
);
export const sumsub: any = createAsyncThunk(
  'auth/sumsub',
  async function () {
    const response = await fetch((process.env.REACT_APP_BASE_URL) + 'sumsub/access-token', { method: 'post', credentials: 'include' });
    const data = await response.json();
    return data
  }
);
export const removeCookie: any = createAsyncThunk(
  'auth/removeCookie',
  async function () {
    const response = await fetch((process.env.REACT_APP_BASE_URL) + 'token/logout', { method: 'post', credentials: 'include' });
    const data = await response.json();
    return data
  }
);
function logout(state: any) {
  state = {
    me: {
      email: "",
      id: "",
      isBlocked: false,
      isKYCPassed: false,
      name: "Гость",
      phone_number: "",
    },
    sumsubToken: "",
    isLoggedIn: false
  }
};

export const authSlice = createSlice(
  {
    name: 'auth',
    initialState: {
      me: {
        email: "",
        id: "",
        isBlocked: false,
        isKYCPassed: false,
        name: "Гость",
        phone_number: "",
      },
      sumsubToken: "",
      isLoggedIn: false
    },
    reducers: {
      logIn: (state) => {
        state.isLoggedIn = true
      },
      logOut: logout,
    },
    extraReducers: {
      [whoAmI.pending]: (state, action) => { },
      [whoAmI.fulfilled]: (state: any, action) => {
        state.me = action.payload
        if (state.me.id) {
          state.isLoggedIn = true;
        }
      },
      [whoAmI.rejected]: (state, action) => {
        state.isLoggedIn = false;
      },
      [sumsub.pending]: (state, action) => { },
      [sumsub.fulfilled]: (state: any, action) => {
        state.sumsubToken = action.payload.accessToken
      },
      [sumsub.rejected]: (state, action) => {
        //state.isLoggedIn = false;
      },
      [removeCookie.pending]: (state, action) => {
      },
      [removeCookie.fulfilled]: (state: any, action) => {
        state.isLoggedIn = false;
      },
      [removeCookie.rejected]: (state, action) => {
        state.isLoggedIn = false
      },
    }
  }
)
export const { logIn, logOut } = authSlice.actions;

// По умолчанию экспортируется редьюсер, сгенерированный слайсом
export default authSlice.reducer;