import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from "axios";
import React, { ReactEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { updateCalcParams } from '../slices/calcParams';

const CalcComponent: React.FC = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const getInitialRates = () => {
        axios
            .get((process.env.REACT_APP_BASE_URL + 'rates/usdt/kgs'))
            .then(response => {
                setRate(response.data);
            })
            .catch(error => {
            });
    }
    const [rate, setRate] = useState({
        weSellAtRate: 1,
        weCanSell: 0,
        weBuyAtRate: 1,
        weCanSpendForBuy: 0
    });
    const [kgs, setkgs] = useState('');
    const [usdt, setUsdt] = useState('');
    const currencyFormatOptions = { minimumFractionDigits: 2 };

    const onSubmintHandler = (e: any) => {
        e.preventDefault();
        if (e.target["0"].value <= 0) {
            e.target["0"].setValidity({ valueMissing: true }, "my message");
        }

        if ((e.target["0"].validity.valid && e.target["1"].validity.valid) && (e.target["0"].value > 0)) {
            navigate("/payment");
        }
    }

    const handlekgsChange: ReactEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        //debugger
        let kgs = Number(event.target.value);
        let usdt = Number((kgs / rate.weSellAtRate).toFixed(2));

        usdt = (usdt > 0) ? usdt : 0;
        if (usdt > rate.weCanSell) {
            usdt = rate.weCanSell;
            kgs = rate.weSellAtRate * usdt;
        };


        setkgs(kgs.toString());
        setUsdt(usdt.toString());
        const payload: any = {
            toBePaid: {
                currency: 'KGS',
                value: kgs
            },
            toBeReceived: {
                currency: 'USDT',
                value: usdt
            }
        }
        dispatch(updateCalcParams(payload))
    }
    const handleUsdtChange: ReactEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let usdt = Number(event.target.value);
        let kgs = rate.weSellAtRate * usdt;

        if (usdt > rate.weCanSell) {
            usdt = rate.weCanSell;
            kgs = rate.weSellAtRate * usdt;
        };
        kgs = Number((kgs).toFixed(2));

        setUsdt(usdt.toString());
        setkgs(kgs.toString());
    }

    useEffect(() => { getInitialRates() }, [kgs, usdt]);
    return (<div >
        <Tabs
            defaultActiveKey="buyUsdt"
            id="fill-tab-example"
            className="mt-3 p-0"
            fill
        >
            <Tab eventKey="buyUsdt" title="Купить USDT" className='shadow '>

                <div className="border mx-auto calc-form-wrapper">
                    <form className="p-5" onSubmit={onSubmintHandler}>
                        <div className='fs-5 fw-bold pb-4'>Доступно к покупке: {rate.weCanSell.toLocaleString('en-US', currencyFormatOptions)} USDT</div>
                        <div className="mb-3">
                            <label className="form-label">Я хочу потратить</label>
                            <div className="form-text">Сумма на которую вы хотите купить USDT</div>
                            <div className="input-group mb-3">
                                <input type="number" className="form-control" value={kgs} onChange={handlekgsChange} required min="0.00" step="0.01" />
                                <span className="input-group-text"><img src={require('../img/kgs.png')} alt="" height='30' /></span>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Я хочу купить</label>
                            <div className="form-text">Сумма покупаемой валюты</div>
                            <div className="input-group mb-3">
                                <input type="number" className="form-control" value={usdt} onChange={handleUsdtChange} min="0.00" step="0.01" required />
                                <span className="input-group-text">
                                    <img src={require('../img/tether-usdt-logo.png')} alt="" height='30' />
                                </span>
                            </div>
                        </div>

                        <div className='alert alert-success'><strong>Итого: </strong><span>{`Ты получишь ${Number(usdt).toString()} USDT за ${Number(kgs).toString()} KGS`}</span></div>

                        <button className="btn btn-primary" type='submit' >
                            Купить USDT <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16"><path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" /></svg>
                        </button>
                    </form>
                </div>
            </Tab>
            <Tab eventKey="sellUsdt" title="Продать USDT в офисе" className='shadow ' >
                <div className=" border mx-auto p-5 bg-white">
                    <h3>А как продать криптовалюту?</h3>
                    <p>На данный момент продажа криптовалюты осуществляется только в офисе:
                        <ul>
                            <li>Встречаемся с Вами в нашем обменном пункту (г.Бишкек, ул. Шопокова, 121/1, БЦ "Red centre", каб. 41);</li>
                            <li>Мы отправляем Вам свой адрес кошелька;</li>
                            <li>Отправляете криптовалюту;</li>
                            <li>Ждем подтверждений на месте;</li>
                            <li>По прибытию подтверждений, фиксируем курс и выдаем Вам поступившую сумму наличными за вычетом нашей комиссии. Вы получили наличные и обмен считается завершенным.</li>
                        </ul>
                    </p>
                </div>
            </Tab>
        </Tabs>
    </div>
    );
}

export default CalcComponent;