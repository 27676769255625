import React, { ReactHTMLElement, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";

import HeaderNav from "../components/HeaderNav";
import Container from 'react-bootstrap/Container';
import Footer from "../components/Footer";
import { Button, Overlay, Row, Spinner, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { useGetMeQuery, useGetOrdersListQuery, useGetQRMutation } from "../slices";
import { useSelector } from 'react-redux';

export default function PaymentPage() {
    const [skip, setToSkip] = useState(true);
    const [orderId, setOrderId] = useState(undefined);
    let { data: ordersList, isLoading: orderListIsLoading } = useGetOrdersListQuery("/" + orderId, { skip, pollingInterval: 5000 });

    const [show, setShow] = useState(false); //Переменная для переключения отображения подсказки
    const target = useRef(null);

    const inputRef = useRef<HTMLInputElement>(null);//


    const [walletAddress, setWalletAddress] = useState("");//переменная для значения адреса кошелька
    const [walletAddressIsValid, setWalletAddressValid] = useState({ isValid: false, walletError: "", isDisabled: false });//результат валидации адреса кошелька
    const { data: meData, isLoading: meIsLoading, error, } = useGetMeQuery("");//получение информации о пользователе

    //функция проверки валидности адреса кошелька
    const checkWalletValidity = async (wallet: any) => {
        const options = {
            method: 'POST',
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            body: JSON.stringify({ address: wallet, visible: true })
        };

        const result = await fetch('https://api.shasta.trongrid.io/wallet/validateaddress', options)
            //THPvaUhoh2Qn2y9THCZML3H815hhFhn5YC
            .then(response => response.json())
            .then(response => {
                if (response.result) {
                    return true
                } else {
                    return false
                }
            })
            .catch(err => console.error(err));
        return result

    };

    //функция обработки изменения адреса кошелька
    const handleWalletChange = async (event: any) => {
        setWalletAddress(event.target.value);
        const walletAddress = event.target.value;
        const options = {
            method: 'POST',
            headers: { accept: 'application/json', 'content-type': 'application/json' },
            body: JSON.stringify({ address: walletAddress, visible: true })
        };

        await fetch('https://api.shasta.trongrid.io/wallet/validateaddress', options)
            //THPvaUhoh2Qn2y9THCZML3H815hhFhn5YC
            .then(response => response.json())
            .then(response => {
                if (response.result) {
                    event.target.setCustomValidity("");
                    setWalletAddressValid({ isValid: event.target.checkValidity(), walletError: "", isDisabled: false });
                } else {
                    event.target.setCustomValidity(response.message)
                    setWalletAddressValid({ isValid: event.target.checkValidity(), walletError: response.message, isDisabled: false });
                }
            })
            .catch(err => console.error(err));
    }


    const navigate = useNavigate();
    const state = useSelector((state: any) => state.calc);

    //функция загрузки QR кода и статус загрузки
    const [getQR, { isLoading: isLoadingQR }] = useGetQRMutation();

    //переменная для признака видимости QR-кода
    const [isVisibleQR, setVisibleQR] = useState(false);
    //переменная для ошибки загрузки QR-кода
    const [qrError, setQRError] = useState("");
    //переменная для хранения QR-кода
    const [qr, setQR]: any = useState({});

    //функция для получения статуса оплаты
    const refresh = async (id: any) => {


        // const url = (process.env.REACT_APP_BASE_URL + 'rates/usdt/kgs')+'cryptochangeorders/' + id;
        // let result = await fetch(url, { credentials: 'include' })
        //     .then(response => response.json())
        //     .then(response => {
        //         if (response.result) { 
        //             return true
        //         } else {
        //             return false
        //         }
        //     })
        //     .catch(err => console.error(err));
        // if (result) { return setTimeout(() => { refresh(id) }, 3000) } else return false
    }


    //Функция обработки введенного адреса кошелька и загрузки QR-кода
    const showQR = async () => {
        if (!walletAddressIsValid.isValid) { setVisibleQR(false); return }
        setWalletAddressValid({ isValid: walletAddressIsValid.isValid, walletError: walletAddressIsValid.walletError, isDisabled: true });
        await getQR({
            currencyPair: "USDT:KGS",
            paymentAmount: state.toBePaid.value,
            payoutAccount: walletAddress
        }).unwrap()
            .then((payload) => {
                setQRError("");
                setQR(payload);
                setOrderId(payload.order.id);
                setToSkip(false);
            })
            .catch((error) => { console.error('rejected', error); setQRError(error.data.message) });
        setVisibleQR(true);
    }

    useEffect(() => {
        if (ordersList?.invoiceStatus === 'paid') {
            navigate('/invoice/' + ordersList.id);
        }
    }, [ordersList]);

    if (meIsLoading) {
        return (
            <>  <HeaderNav />
                <Container >

                    <div className='m-5 border shadow'>
                        <Spinner animation="border" />
                    </div>
                </Container>
                <Footer />
            </>
        )

    }

    if (error) {
        navigate('/signin');
    } else {
        if (!(meData.isKYCPassed)) {
            navigate('/kyc');
        }
    }
    if (state.toBeReceived.value === 0 || state.toBePaid.value === 0) {
        return <>
            <HeaderNav></HeaderNav>
            <Container className="col-lg-7 mx-auto mt-5 container" >
                <div className='row'>
                    <div className='border shadow p-5'>
                        <h1 className="display-5"  >Вы не указали сумму покупки валюты!</h1>
                        <Link className="btn btn-outline-primary" to={{ pathname: "/" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                            </svg>
                            Вернуться к вводу параметров сделки
                        </Link>
                    </div>
                </div>
            </Container>
            <Footer></Footer>
        </>
    }
    return <>
        <HeaderNav></HeaderNav>
        <Container className="col-lg-7 mx-auto mt-5" >
            <div className='row'>
                <div className='border shadow'>
                    <Link className="btn btn-outline-primary" to={{ pathname: "/" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
                        </svg> Назад
                    </Link>
                    <h1 className="display-5" >
                        Покупка USDT
                        <span className="ico">
                            <svg onClick={() => setShow(!show)} ref={target} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="align-top bi bi-question-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                            </svg>
                        </span>
                    </h1>

                    <Overlay target={target.current} show={show} placement="right">
                        {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                                В данный момент оплата возможна только через сервис "О!Деньги", но скоро список платежных систем будет расширен.
                            </Tooltip>
                        )}
                    </Overlay>

                    <div className="py-3">
                        <Button variant="primary" active className="m-1 bg-white" title='Способ оплаты'>
                            <img src="https://dengi.kg/img/logo.svg" height="30"></img>
                        </Button>

                        <Button variant="secondary" disabled className="m-1" title='Способ оплаты'>
                            Visa
                        </Button>
                        <Button variant="secondary" disabled className="m-1" title='Способ оплаты'>
                            MasterCard
                        </Button>
                        <Button variant="secondary" disabled className="m-1" title='Способ оплаты'>
                            Банковский перевод
                        </Button>
                    </div>


                    <section className="py-4" >
                        <div className="row d-flex justify-content-center">
                            <div className="col">
                                <div className="card rounded-3">
                                    <div className="card-body p-4">
                                        <div className="mb-4">

                                            <h3 className='text-left'>Вы покупаете <strong>{state.toBeReceived.value}</strong>USDT за <strong>{state.toBePaid.value}</strong>KGS</h3>
                                        </div>
                                        <form action="">
                                            <div className='wallet'>
                                                <p className="fw-bold mb-4">1. Укажите адрес кошелька для зачисления криптовалюты:
                                                </p>
                                                <span className='text-info'><small>Внимание! Проверьте адрес. Если вы ошибетесь, то мы не сможем вернуть деньги после зачисления!</small></span>
                                                {/* THPvaUhoh2Qn2y9THCZML3H815hhFhn5YC */}
                                                <div className="input-group ">
                                                    <button disabled={walletAddressIsValid.isDisabled} type="button" className="btn btn-outline-secondary" onClick={
                                                        async () => {
                                                            let copiedWallet = await navigator.clipboard.readText()
                                                            setWalletAddress(copiedWallet);

                                                            if (inputRef.current) { inputRef.current.value = copiedWallet };
                                                            inputRef?.current?.dispatchEvent(new Event('change', { composed: true }));
                                                            if (await checkWalletValidity(copiedWallet)) {

                                                                setWalletAddressValid({ isValid: true, walletError: "", isDisabled: false });
                                                            }
                                                            else {
                                                                setWalletAddressValid({ isValid: false, walletError: "Указан некорректный адрес кошелька", isDisabled: false });
                                                            }

                                                            inputRef?.current?.dispatchEvent(new Event('change', { composed: true }));

                                                            // inputRef?.current?.checkValidity();
                                                        }
                                                    } title='Вставить из буфера обмена'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-fill" viewBox="0 0 16 16">
                                                            <path d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Z"></path>
                                                        </svg>
                                                    </button>
                                                    <input ref={inputRef} type="text" value={walletAddress} onInput={handleWalletChange} onChange={handleWalletChange} disabled={walletAddressIsValid.isDisabled} className="form-control" placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
                                                    // pattern="T[A-Za-z1-9]{33}" 
                                                    />
                                                </div> <span className='error-message d-block mb-4'>{walletAddressIsValid.walletError} </span>
                                            </div>

                                            <div className="qr-section row">
                                                <p className="fw-bold mb-4">2. Отсканируйте/нажмите на QR-код и следуйте инструкциям в приложении</p>
                                                {!isVisibleQR ? <Button onClick={showQR} disabled={isLoadingQR || (!walletAddressIsValid.isValid)}>{isLoadingQR ? <Spinner animation="border" className='spinner-border-sm' /> : ""}Показать QR-код для оплаты</Button> : ""}
                                                <div className=" col-xl-6 qr">

                                                    {(isVisibleQR && qrError ? <div className="alert alert-danger my-3">!!! Ошибка: {qrError} !!!</div> : "")}
                                                    {(isVisibleQR && qr.qr ? <div className="my-3 text-center"><a href={qr.linkApp} className='pulse'><img src={qr.qr} className="rounded img-thumbnail" alt="..." /></a></div> : "")}

                                                </div>
                                                <div className='col'>

                                                    {(isVisibleQR && qr.qr ? <div className='text-center'>Ожидание оплаты <br /><Spinner></Spinner></div> : "")}
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </div> </div>
        </Container>
        <Footer></Footer>
    </>
};