import React from 'react';
import HeaderNav from "../components/HeaderNav";
import Container from 'react-bootstrap/Container';
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useGetMeQuery, useGetOrdersListQuery, useLazyGetOrdersListQuery } from "../slices";
import { Spinner } from "react-bootstrap";

export default function Dashboard() {
    const { data: meData, isLoading: meIsLoading, error, } = useGetMeQuery("");
    // let { data: ordersList, isLoading: orderListIsLoading } = useGetOrdersListQuery("", {
    // });
    const [fetchOrders, { data: ordersList, isLoading: orderListIsLoading }] = useLazyGetOrdersListQuery();
    const navigate = useNavigate();
    const onClickResultUpdate = () => {
        fetchOrders('');
    }

    if (meIsLoading || orderListIsLoading) {
        return (
            <>  <HeaderNav />
                <Container >
                    <div className='m-5 border shadow'>
                        <Spinner animation="border" />
                    </div>
                </Container>
                <Footer />
            </>
        )
    }
    if (error) {
        navigate('/signin');
    } else {
        if (!(meData?.isKYCPassed)) {
            navigate('/kyc');
        }
    }
    return (
        <>
            <HeaderNav></HeaderNav>
            <div className="container-fluid mb-5">
                <div className="row">
                    {/*                     
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                        <div className="position-sticky pt-3 sidebar-sticky">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="#">
                                        <span data-feather="home" className="align-text-bottom"></span>
                                        Список заказов на покупку
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="#">
                                        <span className="align-text-bottom"></span>
                                        Настройки
                                    </a>
                                </li>
                            </ul>


                        </div>
                    </nav> */}

                    {/* <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4"> */}
                    <main className="col ms-sm-auto  px-md-4">
                        {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="h2">Личный кабинет</h1>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="btn-group me-2">
                                    <button type="button" className="btn btn-sm btn-outline-secondary">"Экспорт"</button>
                                </div>
                                <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                                    <span data-feather="calendar" className="align-text-bottom"></span>
                                    Эта нелеля
                                </button>
                            </div>
                        </div> */}
                        <h2>Список заказов на покупку <div className="btn btn-primary" onClick={onClickResultUpdate}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                        </svg>
                            {ordersList ? " Обновить" : " Загрузить"}</div></h2>
                        <div className="table-responsive">

                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Время создания</th>
                                        <th scope="col">Сумма к покупке</th>
                                        <th scope="col">Сумма к оплате (фиат)</th>
                                        <th scope="col">Валютная пара</th>
                                        <th scope="col">Статус заказа на покупку</th>
                                        <th scope="col">Статус выплаты по заказу</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordersList ? ordersList.map((order: any, index: any) => (
                                        <tr>
                                            <td><Link to={`/invoice/${order.id}`}>{index + 1}</Link></td>
                                            <td><Link to={`/invoice/${order.id}`}>{(new Date(order.createdAt)).toLocaleString()}</Link></td>
                                            <td>{Number(order.cryptoAmount).toFixed(2)}</td>
                                            <td>{order.fiatAmount}</td>
                                            <td>{order.currencyPair}</td>
                                            <td>{order.invoiceStatus}</td>
                                            <td>{order.payoutStatus}</td>

                                        </tr>
                                    )) : ""}
                                </tbody>
                            </table>
                        </div>
                    </main>
                </div>
            </div>
            <Footer></Footer>
        </>
    )



};
