import  {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'; 
export const kriptaApi = createApi({
    reducerPath: 'kriptaApi',
    tagTypes:['Me','SumSubToken','Orders'],
    baseQuery:fetchBaseQuery({ baseUrl:(process.env.REACT_APP_BASE_URL), credentials: "include"}),
    endpoints: (builder) => ({
        getMe: builder.query({
            query:()=>'profile/me',
            providesTags: ['Me'],
            keepUnusedDataFor: 1,
        }),
        getSumSubToken: builder.query({
            query:()=>({
                url:'sumsub/access-token',
                method: 'POST'
            }),
            providesTags: ['SumSubToken']
        }),
        getOrdersList: builder.query({
            
            query:(id)=>({
                url:(id)?`/cryptochangeorders${id}`:`/cryptochangeorders`
            }),
            providesTags: ['Orders']
        }),
        sendPing: builder.mutation({
            query: (body)=>({
                url:'token/sms/ping',
                method: 'POST',
                body
            }) 
        }),
        sendPong: builder.mutation({
            query: (body)=>({
                url:'token/sms/pong',
                method: 'POST',
                body
            }),
            invalidatesTags:['Me']
        }), 
        logOut: builder.mutation({
            query: (body)=>({
                url:'token/logout',
                method: 'POST',
                body
            }),
            invalidatesTags:['Me']
        }), 
        getQR: builder.mutation({
            query: (body)=>({
                url:'/cryptochangeorders',
                method: 'POST',
                body
            })
        }), 
    })
     
}); 
export const {useGetMeQuery, useGetOrdersListQuery, useLazyGetOrdersListQuery, useSendPingMutation,useSendPongMutation,useGetSumSubTokenQuery, useLogOutMutation, useGetQRMutation} = kriptaApi;