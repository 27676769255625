import React from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import Calc from './pages/Calc';
import KnowYourCustomer from './pages/KYC';
import PaymentPage from './pages/PaymentPage';
import Invoice from './pages/Invoice';
import { RequireAuth } from './hoc/RequireAuth';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Calc />} />
      <Route path='/calc' element={<Navigate to="/calc" replace />} />
      <Route path='/signin' element={<SignIn />} />
      <Route path='/kyc' element={
        <RequireAuth>
          <KnowYourCustomer />
        </RequireAuth>
      } />
      <Route path='/dashboard' element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      } />
      <Route path='/payment' element={
        <RequireAuth>
          <PaymentPage />
        </RequireAuth>
      } />
      <Route path='/invoice/:id' element={
        <RequireAuth>
          <Invoice />
        </RequireAuth>
      } />
    </Routes>
  );
}

export default App;
