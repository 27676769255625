import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export default function Contacts() {
    return (
        <div className='container'>
            <div className='row'>
                <h3 className="my-3 pt-3 display-3" >
                    Контактная информация
                </h3>
                <div className=" shadow my-3 p-3 bg-white">
                    <div className="col-lg-6 float-md-start">
                        <ul className="p-3 list-group list-group-flush">
                            <li className='list-group-item bg-transparent'>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Телефон:</div>
                                    <a href="tel:+996770001118">+996770001118</a>
                                </div>
                            </li>
                            <li className='list-group-item bg-transparent'>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Адрес:</div>
                                    г.Бишкек, ул. Шопокова, 121/1<br />БЦ "Red centre", каб. 41
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 overflow-hidden">
                        <div className='p-l-5'>
                            <YMaps >
                                <Map
                                    defaultState={{
                                        center: [42.877522, 74.615711],
                                        zoom: 16,
                                    }}
                                >
                                    <Placemark geometry={[42.877522, 74.615711]} />
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};