import { createSlice } from '@reduxjs/toolkit'

export const calcSlice = createSlice(
  {
    name: 'calc',
    initialState: {
      toBePaid: {
        currency: 'KGS',
        value: 0
      },
      toBeReceived: {
        currency: 'USDT',
        value: 0
      }
    },
    reducers: {
      updateCalcParams: (state: any, action: any) => { 
        state.toBePaid = action.payload.toBePaid;
        state.toBeReceived = action.payload.toBeReceived;
      }
    },
    extraReducers: {
    }
  }
)
export const { updateCalcParams } = calcSlice.actions;

export default calcSlice.reducer;