import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './auth';
import { kriptaApi } from './kriptaApi';
import calcReducer from './calcParams';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        calc: calcReducer,
        kriptaApi: kriptaApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(kriptaApi.middleware)
}); 
